import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

// Sections
import MainSection from "./sections/estacionamento-rotativo/main";

const IndexPage = () => (
  <Layout contentClass="page product page-product page-parking">
    <SEO title="Estacionamento Rotativo" />
    <Header siteTitle={`Estacionamento Rotativo`} menu={true} />

    <MainSection></MainSection>

    <Footer />
  </Layout>
)

export default IndexPage
